import React, { ReactText } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import hcMore from 'highcharts/highcharts-more'
import { theme } from 'src/styling'
hcMore(Highcharts)
import sha1 from 'sha1'

// TYPES
type BubbleChartInterface = {
  data: PointInterface[]
  height?: number | string
  maxXAxis?: number
  maxYAxis?: number
  width?: number
  xLabel?: string
  yLabel?: string
  xAxisTitle?: string
  yAxisTitle?: string
  tooltipText?: string
  handleClickPoint?: (options: ComparativeRiskPoint | PointInterface) => void
}

interface ComparativeRiskPoint extends PointInterface {
  totalScore: number
}

interface PointInterface {
  x: number
  y: number
  name: string
  id: number
  isFourthPartyCompany?: boolean
  isSharedThirdPartyCompany?: boolean
}

// =================
// UTILITY FUNCTIONS
// =================
const getChartBackgroundColors = (): ReactText[][] => {
  const colors = theme.colors.severity
  return colors.map((color, i) => [i * (1 / (colors.length - 1)), color.replace(', 1)', ', 0.4)')])
}

// ============
// CHART CONFIG
// ============
const generateConfig = (props: BubbleChartInterface): object => {
  const formattedData = props.data.map((point) => ({
    ...point,
    marker: {
      fillColor: point.isSharedThirdPartyCompany
        ? theme.colors.ui.orange
        : point.isFourthPartyCompany
        ? theme.colors.ui.yellow
        : theme.colors.ui.blueDark,
      fillOpacity: 0.7,
      lineColor:
        point.isFourthPartyCompany || point.isSharedThirdPartyCompany ? theme.colors.ui.red : theme.colors.ui.gold,
    },
  }))

  const config = {
    chart: {
      animation: false,
      height: props.height,
      width: props.width,
      plotBackgroundColor: {
        radialGradient: { cx: 0, cy: 1, r: 1.3 },
        stops: getChartBackgroundColors(),
      },
      style: { fontFamily: 'Roboto, sans-serif', cursor: 'crosshair' },
      type: 'bubble',
      zoomType: 'xy',
      resetZoomButton: {
        x: 0,
        y: -30,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      bubble: {
        events: {
          click: (event: { point: { options: ComparativeRiskPoint | PointInterface } }): void =>
            props.handleClickPoint(event.point.options),
        },
        maxSize: 23,
        minSize: 13,
      },
      series: {
        stickyTracking: false,
        cursor: 'pointer',
      },
    },
    series: [
      {
        data: formattedData,
      },
    ],
    title: {
      text: null as any,
    },
    tooltip: {
      backgroundColor: theme.colors.ui.blueDark,
      borderColor: theme.colors.ui.blueDark,
      borderRadius: 8,
      borderWidth: 0,
      followPointer: true,
      padding: 14,
      headerFormat: '',
      pointFormat: `
        <div style="padding-bottom: 0.25rem; color: ${theme.colors.ui.white}"><b>{point.name}</b></div>
        ${
          props.tooltipText
            ? `<div style="color: ${theme.colors.ui.white}">${props.tooltipText}: {point.totalScore}</div>`
            : `<div style="color: ${theme.colors.ui.white}">${props.yAxisTitle}: {point.y}</div><div style="color: ${theme.colors.ui.white}">${props.xAxisTitle}: {point.x}</div>`
        }

      `,
      style: { color: `${theme.colors.ui.white}`, fontSize: theme.fontSizes.mediumLarge, textAlign: 'center' },
      useHTML: true,
    },
    xAxis: {
      gridLineColor: theme.colors.ui.greyVeryLight,
      gridLineWidth: 1,
      labels: {
        style: {
          fontSize: theme.fontSizes.small,
        },
      },
      lineWidth: 0,
      max: props.maxXAxis ? props.maxXAxis : null,
      min: 0,
      tickInterval: props.maxXAxis ? props.maxXAxis / 5 : null,
      tickLength: 0,
      title: {
        text: props.xAxisTitle ? props.xAxisTitle : null,
        style: {
          fontSize: theme.fontSizes.medium,
        },
      },
    },
    yAxis: {
      gridLineColor: theme.colors.ui.greyVeryLight,
      gridLineWidth: 1,
      labels: {
        style: {
          fontSize: theme.fontSizes.small,
        },
      },
      lineWidth: 0,
      max: props.maxYAxis ? props.maxYAxis : null,
      min: 0,
      tickInterval: props.maxYAxis ? props.maxYAxis / 5 : null,
      title: {
        text: props.yAxisTitle ? props.yAxisTitle : null,
        style: {
          fontSize: theme.fontSizes.medium,
        },
      },
    },
  }

  return config
}

// =========
// COMPONENT
// =========
const GlobalBubbleChart: React.FC<BubbleChartInterface> = (props) => {
  const config = generateConfig(props)

  return <HighchartsReact key={sha1(JSON.stringify(props.data))} highcharts={Highcharts} options={config} />
}

GlobalBubbleChart.propTypes = {
  /**
   * The data to be rendered on the bubble chart.
   */
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      totalScore: PropTypes.number,
      name: PropTypes.string,
      isFourthPartyCompany: PropTypes.bool,
      isSharedThirdPartyCompany: PropTypes.bool,
    })
  ).isRequired,
  /**
   * Defines the height of the area chart, in pixels.
   */
  height: PropTypes.number,
  /**
   * The maximum value of the bubble chart xyaxis.
   */
  maxXAxis: PropTypes.number,
  /**
   * The maximum value of the bubble chart y-axis.
   */
  maxYAxis: PropTypes.number,
  /**
   * Defines the width of the area chart, in pixels.
   */
  width: PropTypes.number,
  /**
   * Defines the label for a bubble's x value when hovering over a bubble.
   */
  xLabel: PropTypes.string,
  /**
   * Defines the label for a bubble's x value when hovering over a bubble.
   */
  yLabel: PropTypes.string,
  /**
   * Title of x axis
   */
  xAxisTitle: PropTypes.string,
  /**
   * Title of x axis
   */
  yAxisTitle: PropTypes.string,
  /**
   * Tool
   */
  tooltipText: PropTypes.string,
}

GlobalBubbleChart.defaultProps = {
  data: null,
  height: null,
  maxXAxis: null,
  maxYAxis: null,
  width: null,
  xLabel: 'x',
  yLabel: 'y',
}

export { GlobalBubbleChart }
