import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Checkbox,
  Theme,
  Box,
  Button,
  TextField,
} from '@material-ui/core'
import ReactTooltip from 'react-tooltip'
import { theme } from 'src/styling'
import RemoveIcon from '@material-ui/icons/Remove'
import InfiniteScroll from 'react-infinite-scroll-component'
import SnackBar from 'src/context/snackbar'
import { Dialog, Loaders, TitleWithToolTip } from 'components'
import axios from 'axios'
import { getJwtPayload } from 'src/auth/payload'
import { Clear, Done } from '@material-ui/icons'
import { mapType } from 'src/modals/digital-risk-protection/company-privacy-data'
import moment from 'moment'
/**
 * Use this class for a generic table that generates table from array of objects.
 * @param items An array, where each index represents a row. Each element in the array should be an object e.g. [{name, age, date},{name, age, date},{name, age, date},...]
 * @param rowKeys Keys that should be used to index each index of items. e.g ["name","age","date"] -> will generate a table where each row, has a name, age and date
 *                rowkeys can represent nested objects. e.g. if items looks like this [{node:{data:{name, age,date}}}, {node:{data:{name, age,date}}}, ...] rowkeys could be:  ["node.data.name","node.data.age","node.data.date"]
 */

type Props = {
  headings?: string[]
  items: any[]
  rowKeys: string[]
  hasMore: boolean
  fetchMore: () => void
  handleChangeCheckbox: (item: any) => void
  handleChangeCheckboxNote: (item: any, note: string) => void
  isCheckBoxChecked: (items: any[]) => boolean
  isCheckBoxDisabled: boolean
  verticalAlign?: 'top' | 'middle' | 'bottom' | ''
  tableName: string
}

type StyleProps = {
  isCheckBoxDisabled: boolean
}

const useStyles = makeStyles<Theme, StyleProps>({
  tableHeaderCell: { lineHeight: '1.35' },
  tableCell: {
    padding: '14px',
    fontSize: theme.fontSizes.medium,
    lineHeight: '1.3',
    borderBottom: `0.9px solid ${theme.colors.ui.greyLight}`,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.fontSizes.mediumSmall,
    },
  },
  loadingMessage: { ...theme.customStyles.loadingMoreMessage },
  icon: { ...theme.customStyles.icon },
  markAsColumn: ({ isCheckBoxDisabled }) => ({
    display: isCheckBoxDisabled ? 'none' : 'table-cell',
    padding: 0,
    width: '42px',
  }),
})

/**
 * Helper function. Provided object: {a:{b:"Hello"}} and path: "a.b" returns "Hello"
 * @param obj Object to process
 * @param path Key to extract from object
 */

type objType = {
  [key: string]: objType | string
}

const getNodeData = (obj: objType, path: string) => {
  return path.split('.').reduce((currentValue, key) => {
    if (!currentValue) return null
    return key in currentValue
      ? typeof currentValue[key] === 'string' && currentValue[key].trim() === ''
        ? null
        : currentValue[key]
      : null
  }, obj)
}

const getEncodeBreachedCredsDescription = (data) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = data
  return txt.value
}
const getTypeTitle = (type) => {
  const typeObj = mapType.find((t) => t.key === type)
  return typeObj ? typeObj.title : type
}

const leaf = (
  obj: objType,
  path: string,
  handleViewMore = null,
  isActionedTable = false,
  isBreachedCredentialsTableAndCount = false
) => {
  const nodeData = getNodeData(obj, path) as string

  const data = path === 'node.breachMetadata.description' ? getEncodeBreachedCredsDescription(nodeData) : nodeData
  if (path.includes('node.applied')) {
    if (['Acknowledged', 'Accepted'].includes(obj?.node?.userItemStatus?.description)) return <RemoveIcon />
    if (!data) return <RemoveIcon />
    return <Done />
  }

  if (!data) return null

  if (path.includes('node.hostsApplication.certSubjectCn') && obj?.node?.isDifferentDomain) {
    return (
      <div style={{ display: 'flex' }}>
        <TitleWithToolTip
          title=""
          contentSentence={
            'Any expired certificate discovered on an asset is shown. Including those with common names (CN) that do not match the domain related to the asset.'
          }
        />
        <span style={{ marginLeft: '0.3rem' }}>{data}</span>
      </div>
    )
  }
  if (path.includes('node.issueSentence') || path.includes('node.recommendation')) {
    return data.split(',').map((item, index) => <li key={item + index}>{item}</li>)
  }

  if (isBreachedCredentialsTableAndCount) {
    return (
      <div>
        <a style={{ fontSize: '0.8rem', cursor: 'pointer' }}>{data}</a>
      </div>
    )
  }
  if (path === 'node.company.name') {
    return (
      <div>
        <a
          href={`/third-party-risk?company=${obj?.node?.company?.id},${obj?.node?.company?.name},company`}
          style={{ fontSize: '0.8rem', cursor: 'pointer' }}
        >
          {data}
        </a>
      </div>
    )
  }
  if (path === 'node.intrep.title') {
    return (
      <div>
        <a
          href={`/intelligence?tag=${obj?.node?.intrep?.title}&from=2007-01-08&to=${moment().format('YYYY-MM-DD')}`}
          style={{ fontSize: '0.8rem', cursor: 'pointer' }}
        >
          {data.length > 30 ? `${data.slice(0, 30)}...` : data}
        </a>
      </div>
    )
  }
  if (path === 'node.threatActor') {
    return (
      <div>
        {obj?.node?.threatActor.toLowerCase() !== 'unknown' ? 
        <a href={`/profiles?tag=${obj?.node?.threatActor}`} style={{ fontSize: '0.8rem', cursor: 'pointer' }}>
          {data}
        </a>
        : <div>{data} </div>
        }
      </div>
    )
  }

  return data
}

const widthForCertificatesTable: { [key: string]: string } = {
  'Expiry Date': '17%',
}

const GlobalTableNew: React.FC<Props> = ({
  headings,
  items,
  rowKeys,
  fetchMore,
  hasMore,
  handleChangeCheckbox,
  isCheckBoxChecked,
  isCheckBoxDisabled,
  verticalAlign = 'middle',
  tableName,
  handleViewMore,
  isActionedTable,
  handleChangeCheckboxNote,
}) => {
  const classes = useStyles({ isCheckBoxDisabled })
  const [credentialsModalOpen, setCredentialsModalOpen] = useState(false)
  const computeCellStyles = (headCell: string) => {
    if (tableName === 'certificates') return { width: widthForCertificatesTable[headCell] || 'auto' }
    return {}
  }
  const [collapsedRow, setCollapsedRow] = useState(null)
  const [exportingCredentials, setExportingCredentials] = useState(false)
  const { enqueueSnackbar } = SnackBar.Consumer()
  const handleClickCollapsedRow = (item) => {
    if (collapsedRow?.node?.id === item.node.id) {
      setCredentialsModalOpen(false)
      setCollapsedRow(null)
    } else {
      setCredentialsModalOpen(true)
      setCollapsedRow(item)
    }
  }

  const handleExportCredentials = ({ src, scanIterId }) => {
    const { companyId } = getJwtPayload()
    setExportingCredentials(true)
    axios
      .get(`/credentials/${companyId}/download/csv?credentials=${src},${scanIterId}`)
      .then((response) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        link.setAttribute('download', `credentials-${src}.csv`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        setExportingCredentials(false)
      })
      .catch(() => {
        //Display error message
        enqueueSnackbar(`Sorry, we couldn't start your download. Please try again later.`, {
          variant: 'error',
        })
        setExportingCredentials(false)
      })
  }

  const [tempCheckbox, setTempCheckbox] = useState([])

  return (
    <InfiniteScroll
      height={450}
      dataLength={items.length}
      next={fetchMore}
      hasMore={hasMore}
      loader={<h4 className={classes.loadingMessage}>Loading more...</h4>}
    >
      {tableName === 'breached-credentials' && credentialsModalOpen && (
        <Dialog width="50vw" onClose={() => setCredentialsModalOpen(false)}>
          <Button
            onClick={() =>
              handleExportCredentials({
                src: collapsedRow?.node?.breachMetadata?.src,
                scanIterId: collapsedRow?.node?.scanIterId,
              })
            }
            style={{ float: 'right' }}
          >
            {exportingCredentials ? <Loaders.Spinner size={20} /> : 'Export'}
          </Button>
          <Box marginBottom={'30px'} fontSize={'1.4rem'} textAlign={'left'}>
            Breached Credentials
          </Box>
          <Box height={'65vh'} overflow={'auto'}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {['Email', 'Username', 'Count'].map((headCell: string, index: number) => {
                    return (
                      <TableCell align="left" key={index} className={`${classes.tableHeaderCell} ${classes.tableCell}`}>
                        {headCell}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {collapsedRow?.node?.companyCredentials?.edges.map(({ node }, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.tableCell}
                        key={index}
                        align="left"
                        style={{ verticalAlign: verticalAlign, wordWrap: 'break-word' }}
                      >
                        {node.email}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        key={index}
                        align="left"
                        style={{ verticalAlign: verticalAlign, wordWrap: 'break-word' }}
                      >
                        {node.user ? node.user : <RemoveIcon />}
                      </TableCell>

                      <TableCell
                        data-tip
                        data-for={`tooltip-${node.email}`}
                        className={classes.tableCell}
                        key={index}
                        align="left"
                        style={{
                          verticalAlign: verticalAlign,
                          wordWrap: 'break-word',
                          paddingLeft: '2rem',
                          cursor: node.count > 1 && 'default',
                        }}
                      >
                        {node.count > 1 && (
                          <ReactTooltip
                            backgroundColor={theme.colors.ui.blueDark}
                            id={`tooltip-${node.email}`}
                            place="right"
                            effect="solid"
                          >
                            {node.count} distinct records in this breach
                          </ReactTooltip>
                        )}
                        {node.count ? node.count : <RemoveIcon />}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Dialog>
      )}
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {!isActionedTable && tempCheckbox.length > 0 && (
              <TableCell
                align="left"
                key={headings.length + 1}
                className={`${classes.tableHeaderCell} ${classes.tableCell}`}
              />
            )}
            <TableCell className={classes.markAsColumn}></TableCell>
            {headings.map((headCell: string, index: number) => {
              const cellStyles = computeCellStyles(headCell)
              return (
                <>
                  <TableCell
                    align="left"
                    key={index}
                    className={`${classes.tableHeaderCell} ${classes.tableCell}`}
                    style={cellStyles}
                    data-for={`tooltip-${headCell}`}
                    data-tip
                  >
                    {headCell}
                    <ReactTooltip
                      disable={headCell !== 'Applied'}
                      backgroundColor={theme.colors.ui.blueDark}
                      place="bottom"
                      effect="solid"
                      id={`tooltip-${headCell}`}
                    >
                      Applied to the current score
                    </ReactTooltip>
                  </TableCell>
                </>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(
            (item: any, index: number): React.ReactElement => (
              <>
                <TableRow key={index} style={{ backgroundColor: item?.node?.isCurrent && '#f6f6f6' }}>
                  <TableCell className={classes.markAsColumn} style={{ verticalAlign: verticalAlign }}>
                    <Checkbox
                      onChange={() => {
                        if (tempCheckbox.includes(JSON.stringify(item))) {
                          setTempCheckbox(tempCheckbox.filter((itemTemp) => JSON.stringify(item) !== itemTemp))
                        } else {
                          setTempCheckbox([...tempCheckbox, JSON.stringify(item)])
                        }

                        handleChangeCheckbox([item])
                      }}
                      checked={isCheckBoxChecked([item])}
                    />
                  </TableCell>
                  {!isActionedTable && isCheckBoxChecked([item], true) && (
                    <TableCell
                      data-tip
                      data-for={`tooltip-note-${index}`}
                      className={classes.tableCell}
                      key={rowKeys.length + 1}
                      align="left"
                      style={{
                        width: '120px',
                        verticalAlign: verticalAlign,
                        wordWrap: 'break-word',
                      }}
                    >
                      <ReactTooltip
                        backgroundColor={theme.colors.ui.blueDark}
                        id={`tooltip-note-${index}`}
                        place="right"
                        effect="solid"
                      >
                        Note will be saved when item status is set
                      </ReactTooltip>
                      {isCheckBoxChecked([item]) && (
                        <TextField
                          value={item?.note}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChangeCheckboxNote([item], event.target.value)
                          }}
                          size={'small'}
                          style={{ width: '120px' }}
                          id="standard-basic"
                          placeholder="Add a note..."
                          variant="standard"
                        />
                      )}
                    </TableCell>
                  )}
                  {rowKeys.map((key, index) => {
                    const isBreachedCredentialsTableAndCount =
                      key === 'node.count' &&
                      tableName === 'breached-credentials' &&
                      item?.node?.companyCredentials?.edges.length

                    return (
                      <TableCell
                        onClick={() => isBreachedCredentialsTableAndCount && handleClickCollapsedRow(item)}
                        className={classes.tableCell}
                        key={index}
                        align="left"
                        style={{
                          width: key === 'node.userItem.note' && '140px',
                          verticalAlign: verticalAlign,
                          wordWrap: 'break-word',
                          whiteSpace: ['node.insertTimestamp', 'node.type'].includes(key) && 'nowrap',
                          cursor: isBreachedCredentialsTableAndCount && 'pointer',
                        }}
                      >
                        {key === 'node.userItem.note' && isCheckBoxChecked([item]) ? (
                          <Box data-tip data-for={`tooltip-note-${JSON.stringify(item)}`}>
                            <ReactTooltip
                              backgroundColor={theme.colors.ui.blueDark}
                              id={`tooltip-note-${JSON.stringify(item)}`}
                              place="right"
                              effect="solid"
                            >
                              Note will be saved when item status is set
                            </ReactTooltip>

                            <TextField
                              value={item?.note}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleChangeCheckboxNote([item], event.target.value, null, null, true)
                              }}
                              size={'small'}
                              style={{ width: '120px' }}
                              id="standard-basic"
                              placeholder="Add a note..."
                              variant="standard"
                            />
                          </Box>
                        ) : key === 'node.type' ? (
                          getTypeTitle(item?.node?.type)
                        ) : (
                          leaf(item, key, handleViewMore, isActionedTable, isBreachedCredentialsTableAndCount) ?? (
                            <RemoveIcon
                              style={key === 'node.userItem.note' ? { display: 'none' } : {}}
                              className={classes.icon}
                            />
                          )
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </>
            )
          )}
        </TableBody>
      </Table>
    </InfiniteScroll>
  )
}

export { GlobalTableNew }
